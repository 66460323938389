import React, { useState } from "react";

import RhymeForm from "./RhymeForm";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../auth/LoadingSpinner";
import useModal from "../../hooks/useModal";
import ColabAPI from "../../api/colabApi";
import "./RhymeTest.css";
function Rhymetest(props) {
  const [word, setWord] = useState("");
  const [rhymes, setRhymes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isShowing, toggle } = useModal();

  const handleClear = () => {
    setWord("");
    setRhymes([]);
  };
  const findRhymes = async (word) => {
    if (word === "") {
      handleClear();
      return;
    }
    try {
      setWord(word);
      setLoading(true);
      const foundRhymes = await ColabAPI.getRhymes(word);
      setRhymes(foundRhymes);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleModalClose = () => {
    setLoading(false);
    handleClear();
    toggle();
  };
  return (
    <>
      <button onClick={toggle} className="btn btn-accept">
        Rhyme Time
      </button>
      <Modal
        show={isShowing}
        onHide={handleModalClose}
        className="RhymeModal"
        centered
      >
        <Modal.Header className="bg-dark text-light" closeButton>
          <Modal.Title>Find Rhymes</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark">
          <div>
            <div>
              <RhymeForm
                search={findRhymes}
                handleClear={handleClear}
                rhymes={rhymes}
              />
            </div>
            {loading && <LoadingSpinner />}
            {word.length > 0 && !loading && (
              <h3 style={{ color: "white" }} className="ml-3 mt-4">
                Words that rhyme with '{word}':{" "}
              </h3>
            )}
            <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
              <ol className="list-group ml-3 mr-3 mt-3">
                {rhymes.map((rhyme, idx) => (
                  <li
                    key={idx}
                    className="list-group-item list-group-item-success text-secondary d-flex justify-content-between align-items-center"
                  >
                    {rhyme.word}

                    <span className="badge badge-accept badge-pill">
                      Score: {rhyme.score}
                    </span>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Rhymetest;

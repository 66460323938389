export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const UDPATE_USER = "UPDATE_USER";
export const UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE";
export const ADD_USER_PROJECT = "ADD_USER_PROJECT";
export const ADD_USER_COWRITE = "ADD_USER_COWRITE";
export const UPDATE_USER_PROJECT = "UPDATE_USER_PROJECT";
export const DELETE_USER_PROJECT = "DELETE_USER_PROJECT";
export const FIND_USERS = "FIND_USERS";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_PROJECT = "GET_PROJECT";
